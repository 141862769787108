import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import PlayerControls from './PlayerControls';
import CountdownSnackbar from './CountdownSnackbar';
import { useDispatch } from 'react-redux';
import { setSelectedVideoDetails } from '../../../features/coursesSlice';
import { setCurrentlyPlayingSubVideo } from '../../../features/PlayerTocSlice';
import { AppDispatch } from '../../../store';
import { getVideoFiles,getFileMp4FileSize } from '../../../api/serverApis/filesApi';
import { cleanTheDot } from '../../searchComponents/UtilityFunctions';
import { getFileFromCache, saveFileToCache } from '../../../utils/indexedDb'

interface ReactStreamingComponentProp {
  videoUrl: string;
  startTime?: string;
  frameTime?: string;
  endTime?: string;
  onTakeTest: (quizData: any) => void;
  movieList: any[];
  videoData?: any;
  onVideoComplete?: (videoId: string, subVideoId: string) => void;
}

const ReactStreamingComponent: React.FC<ReactStreamingComponentProp> = ({
  videoUrl,
  startTime,
  frameTime,
  endTime,
  onTakeTest,
  videoData,
  movieList,
  onVideoComplete,
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [videoBlobUrl, setVideoBlobUrl] = useState<string | null>(null);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chunkArray, setChunkArray] = useState<Blob[]>([]);
  const [chunkSize] = useState(5 * 1024 * 1024); // 5MB chunks
  const [currentStart, setCurrentStart] = useState(0);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [startTimeCurrent, setStartTimeCurrent] = useState(startTime || "0");
  const [endTimeCurrent, setEndTimeCurrent] = useState(endTime || "0");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredTime, setHoveredTime] = useState<number | null>(null);
  const [blockEnd, setBlockEnd] = useState(false);
  const [bufferedEnough, setBufferedEnough] = useState(false);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [loadedChunks, setLoadedChunks] = useState<{ start: number; end: number }[]>([]);
  const [chunkDict, setChunkDict] = useState<{ [key: string]: Blob }>({});
  const [isFullyDownloaded, setIsFullyDownloaded] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Reset all states for a new video URL
    setVideoBlobUrl(null);
    setPlaying(false);
    setLoading(true);
    setChunkDict({});
    setCurrentStart(0);
    setBufferedEnough(false);
    fetchFileSize();
  }, [videoUrl]);


const getStartTime = () => {
    if (startTime && startTime !== "-1") {
      return cleanTheDot(startTime);
    } else if (frameTime) {
      return cleanTheDot(frameTime);
    } else {
      return "0";
    }
  };

  const getEndTime = () => {
    if (endTime && endTime !== "-1") {
      return cleanTheDot(endTime);
    } else {
      return duration.toString();
    }
  };

  const startInSeconds = parseInt(getStartTime(), 10);
  const endInSeconds = parseInt(getEndTime(), 10);

  const fetchFileSize = async () => {
    try {
      const response = await getFileMp4FileSize(videoUrl);
      setFileSize(response.data.fileSize);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching file size:", error);
    }
  };


 const loadChunksToStartTime = async () => {
    const startByte = Math.ceil(parseFloat(startTime || "0") * (fileSize! / videoData.allMovieLength));
    let start = 0;

    while (start < startByte + chunkSize) {
      const end = start + chunkSize;
      await loadChunk(start, end);
      start += chunkSize;
    }

    setCurrentStart(start);
    setLoading(false);
    setBufferedEnough(true);
  };
const isChunkLoaded = (start: number, end: number) => {
    return loadedChunks.some(chunk => chunk.start === start && chunk.end === end);
  };


const loadChunk = async (start: number, end: number) => {
    const chunkKey = `${start}-${end}`;

    // Check if the chunk is already downloaded
    if (chunkDict[chunkKey]) return;

    try {
      const response = await getVideoFiles(videoUrl, start, end - 1);
      const newChunkBlob = new Blob([response.data], { type: 'video/mp4' });
        
      setChunkDict(prevDict => {
        const updatedDict = { ...prevDict, [chunkKey]: newChunkBlob };
        // Combine chunks to update videoBlobUrl
        const combinedBlob = new Blob(Object.values(updatedDict), { type: 'video/mp4' });
        setVideoBlobUrl(URL.createObjectURL(combinedBlob));
        
        return updatedDict;
      });
    
      if (end >= fileSize!) {
        setIsFullyDownloaded(true);
        const combinedBlob = new Blob(Object.values(chunkDict), { type: 'video/mp4' });
        await saveFileToCache(videoUrl, combinedBlob); // Save full video to IndexedDB
      }
    } catch (error) {
      console.error("Error loading video chunk:", error);
    }
  };

  const loadNextChunk = async () => {
    if (isFullyDownloaded) return;
    const nextStart = currentStart;
    const nextEnd = Math.min(nextStart + chunkSize, fileSize || nextStart + chunkSize);
    await loadChunk(nextStart, nextEnd);
    setCurrentStart(nextEnd);
  };

  useEffect(() => {
    const loadFromCacheOrFetch = async () => {
      const cachedFile = await getFileFromCache(videoUrl);
      if (cachedFile) {
        setVideoBlobUrl(URL.createObjectURL(cachedFile));
        setIsFullyDownloaded(true);
        setLoading(false);
      } else {
        fetchFileSize();
      }
    };
    loadFromCacheOrFetch();
  }, [videoUrl]);

 useEffect(() => {
    if (fileSize) {
        loadChunksToStartTime();// Adjust chunk start based on start time
        setPlayedSeconds(startInSeconds);
    }
  }, [fileSize, startInSeconds]);
  useEffect(() => {
    if (fileSize && !isFullyDownloaded) {
      loadNextChunk();
    }
  }, [currentStart, fileSize, isFullyDownloaded]);
  useEffect(() => {
    if (readyToPlay && bufferedEnough && videoBlobUrl) {
      setPlaying(true);
    }
  }, [readyToPlay, bufferedEnough, videoBlobUrl]);

  const handlePlayerReady = () => {
    if (readyToPlay) {
      setPlaying(true);
    }
  };


  useEffect(() => {
    setBlockEnd(true);
    setLoading(true);
    setPlaying(true);
    const start = getStartTime();
    const end = getEndTime();
    setStartTimeCurrent(start);
    setEndTimeCurrent(end);
    setPlayedSeconds(parseInt(start, 10));

    if (videoData) {
      dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));
    }

    const seekToStartTime = async () => {
        const start = getStartTime();
      await new Promise((resolve) => setTimeout(resolve, 500));
      playerRef.current?.seekTo(parseInt(start, 10));
      setLoading(false);
      setPlaying(true);
      setBlockEnd(false);
    };

    if (ready && playerRef.current && start) {
        seekToStartTime();
        setPlayedSeconds(parseInt(start, 10));
        setLoading(false);
        setPlaying(true);
  
      }
  }, [videoData, startTime, endTime, frameTime, ready]);


const handleProgress = (state: { playedSeconds: number }) => {
    const start = parseInt(startTimeCurrent, 10);
    const end = parseInt(endTimeCurrent, 10);
    const currentTime = state.playedSeconds;
  
    // Ensure the video starts from the designated start time if the current time is before it
    if (currentTime < start) {
      playerRef.current?.seekTo(start);
      return;
    }
  
    // Update the played seconds state
    setPlayedSeconds(currentTime);
  
    // Calculate progress within the current chapter based on start and end times
    let currentProgress = ((currentTime - start) / (end - start)) * 100;
    currentProgress = Math.min(Math.max(currentProgress, 0), 100);
  
    // Dispatch current video data if available
    if (videoData) {
      dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));
    }
    whenTheChapterEnded(currentTime);
    // Check for nearing end of current chunk and load next chunk if within streaming range
    const endBytePosition = end * (fileSize! / duration);
    if (fileSize && currentStart < endBytePosition && currentStart - endBytePosition < chunkSize) {
      //loadNextChunk(currentStart, Math.min(currentStart + chunkSize, endBytePosition));
      loadNextChunk();
    }
    
    // Check if the video has reached the chapter end time to halt playback
    if (currentTime >= end) {
      setPlaying(false);
    }
  };
    const whenTheChapterEnded = (currentTime: number) => {
        const start = parseInt(startTimeCurrent, 10);
        const end = parseInt(endTimeCurrent, 10);
        // if (!snackbarOpen && currentTime >= end) {
        if (currentTime >= end && !blockEnd) {
        //setPlaying(false);
        // setSnackbarOpen(true);
        let movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
            if (movieIndex !== -1) {

                handleNextVideo();
            }
        }
    }
  const handlePlayPause = () => {
    setPlaying(!playing);
  };
  const handleSeekChange = (event: Event, newValue: number | number[]) => {
    const newTime = Array.isArray(newValue) ? newValue[0] : newValue;
    playerRef.current?.seekTo(newTime);
    setPlayedSeconds(newTime);
  };
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
    setVolume(newVolume / 100);
    setMuted(newVolume === 0);
  };

  const handleMute = () => {
    setMuted(prev => !prev);
    setVolume(muted ? volume : 0);
  };

  const handlePlaybackRateChange = (rate: number) => {
    setPlaybackRate(rate);
    setAnchorEl(null);
  };

  const handleSliderHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const sliderWidth = event.currentTarget.clientWidth;
    const offsetX = event.nativeEvent.offsetX;
    const start = parseInt(startTimeCurrent, 10);
    const end = parseInt(endTimeCurrent, 10) || playerRef.current?.getDuration() || duration;
    const hoverTime = start + ((offsetX / sliderWidth) * (end - start));
    setHoveredTime(hoverTime);
  };

  const handleSliderLeave = () => setHoveredTime(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleMovieMenuClick = (event: React.MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget);

  const handleMovieMenuClose = () => setMenuAnchorEl(null);

  const handleNextVideo = () => {
    const movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex !== -1) {
      const nextMovie = movieList[movieIndex + 1];
      if (nextMovie) {
        dispatch(setSelectedVideoDetails({
          movieId: nextMovie.movieId,
          id: nextMovie.id,
          courseId: nextMovie.courseId || ""
        }));
        setPlaying(true);
      }
    }
  };

  const handlePreviousVideo = () => {
    const movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex !== -1) {
      const prevMovie = movieList[movieIndex - 1];
      if (prevMovie) {
        dispatch(setSelectedVideoDetails({
          movieId: prevMovie.movieId,
          id: prevMovie.id,
          courseId: prevMovie.courseId || ""
        }));
        setPlaying(true);
      }
    }
  };

  const handleTakeTest = () => {
    setPlaying(false);
    onTakeTest({
      articleId: videoData.movieId,
      topicNumber: videoData.id
    });
  };

  const handleMovieSelect = (movie: any) => {
    dispatch(setSelectedVideoDetails({
      movieId: movie.movieId,
      id: movie.id,
      courseId: movie.courseId || ""
    }));
    setMenuAnchorEl(null);
  };

  return (
    <Box
      sx={{ position: 'relative', width: '100%' }}
      onMouseMove={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      {loading && (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <CircularProgress />
        </Box>
      )}
      <ReactPlayer
        ref={playerRef}
        url={videoBlobUrl || undefined}
        playing={playing && bufferedEnough && !loading} 
        controls={false}
        width="100%"
        height="585px"
        onReady={() => setReady(true)}
        onProgress={handleProgress}
        onDuration={(duration) => setDuration(duration)}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        muted={muted}
        volume={volume}
        playbackRate={playbackRate}
      />
            <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '80%',
          backgroundColor: 'transparent',
          zIndex: 2,
          pointerEvents: 'auto',// Blocks interaction with underlying player
          cursor: 'pointer'
        }}
        onClick={(e) => {
          e.stopPropagation();
          handlePlayPause();
        }}
      />
      {controlsVisible && (
        <PlayerControls
          startTimeCurrent={startInSeconds.toString()}
          endTimeCurrent={endInSeconds?.toString() || duration.toString()}
          playing={playing}
          playedSeconds={playedSeconds}
          duration={duration}
          volume={volume}
          muted={muted}
          playbackRate={playbackRate}
          anchorEl={anchorEl}
          menuAnchorEl={menuAnchorEl}
          hoveredTime={hoveredTime}
          handlePlayPause={handlePlayPause}
          handleVolumeClick={handleMute}
          handleVolumeChange={handleVolumeChange}
          handleSeekChange={handleSeekChange}
          handleSliderHover={handleSliderHover}
          handleSliderLeave={handleSliderLeave}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handlePlaybackRateChange={handlePlaybackRateChange}
          handleMovieMenuClick={handleMovieMenuClick}
          handleMovieMenuClose={handleMovieMenuClose}
          handleNextVideo={handleNextVideo}
          handlePreviousVideo={handlePreviousVideo}
          handleTakeTest={handleTakeTest}
          handleMovieSelect={handleMovieSelect}
          movieList={movieList}
        />
      )}
    </Box>
  );
};

export default ReactStreamingComponent;
