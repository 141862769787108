
import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const api = '/api/imageService/';

export const getImagesFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}images/${directoryAndFile}`, { responseType: 'blob' });
}
// export const getVideoFiles = async (directoryAndFile: string) => {
//   return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, { responseType: 'blob' });
// }
export const getVideoFiles = async (directoryAndFile: string, start: number, end: number) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    headers: {
      Range: `bytes=${start}-${end}`
    },
    responseType: 'blob'
  });
};
export const getVideoFilesWithoutRange = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    responseType: 'blob'
  });
};
export const getFileMp4FileSize = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    headers: {
      Range: 'metadataFileSize',
      'Content-Type': 'video/mp4'
    }
  });
}
export const getFileMp4MetaData = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    headers: {
      Range: 'metadata',
      'Content-Type': 'video/mp4'
    }
  });
}
export const getPdfFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}pdf/${directoryAndFile}`, { responseType: 'blob' });
}

