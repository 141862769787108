import React, { useEffect, useState } from 'react';
import { getFileFromCache, saveFileToCache } from '../../../utils/indexedDb';
import { getVideoFiles, getFileMp4FileSize } from '../../../api/serverApis/filesApi';

interface VideoDownloaderProps {
  videoUrl: string;
  onDownloadComplete: (blobUrl: string) => void;
}

const VideoDownloader: React.FC<VideoDownloaderProps> = ({ videoUrl, onDownloadComplete }) => {
  const [isFullyDownloaded, setIsFullyDownloaded] = useState(false);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [chunkSize] = useState(5 * 1024 * 1024); // 5MB chunks
  const [chunkDict, setChunkDict] = useState<{ [key: string]: Blob }>({});

  useEffect(() => {
    const checkCacheAndDownload = async () => {
      const cachedFile = await getFileFromCache(videoUrl);
      if (cachedFile) {
        // If cached, use it directly
        const blobUrl = URL.createObjectURL(cachedFile);
        onDownloadComplete(blobUrl);
        setIsFullyDownloaded(true);
      } else {
        // If not cached, start the download
        fetchFileSize();
      }
    };

    checkCacheAndDownload();
  }, [videoUrl]);

  const fetchFileSize = async () => {
    try {
      const response = await getFileMp4FileSize(videoUrl);
      setFileSize(response.data.fileSize);
      downloadInBackground();
    } catch (error) {
      console.error('Error fetching file size:', error);
    }
  };

  const downloadInBackground = async () => {
    let start = 0;
    while (start < (fileSize || 0)) {
      const end = Math.min(start + chunkSize, fileSize || start + chunkSize);
      await downloadChunk(start, end);
      start += chunkSize;
    }
    // Save completed file to cache and notify the main component
    const combinedBlob = new Blob(Object.values(chunkDict), { type: 'video/mp4' });
    await saveFileToCache(videoUrl, combinedBlob);
    const blobUrl = URL.createObjectURL(combinedBlob);
    onDownloadComplete(blobUrl);
    setIsFullyDownloaded(true);
  };

  const downloadChunk = async (start: number, end: number) => {
    const chunkKey = `${start}-${end}`;
    try {
      const response = await getVideoFiles(videoUrl, start, end - 1);
      const newChunkBlob = new Blob([response.data], { type: 'video/mp4' });

      setChunkDict(prevDict => ({
        ...prevDict,
        [chunkKey]: newChunkBlob,
      }));
    } catch (error) {
      console.error("Error downloading chunk:", error);
    }
  };

  return null; // This component doesn't render anything visible
};

export default VideoDownloader;